/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.cover-hidden-text {
  opacity: 0;
  background-color: transparent;
  transition: all 1s ease-in-out;
  cursor: pointer;
}

.cover-hidden-text:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1
}

.cover-hidden-text>div {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.cover-hidden-text:hover>div {
  opacity: 1;
  transform: translateY(-50px)
}

.cover-scale-image {
  transition: all 1s ease-in-out;
}

.cover-scale-image.active {
  transform: scale(1.1);
}

.animate-height {
  transition: min-height .3s ease-in-out;
  min-height: 230px;
}

.animate-height.active {
  transition: min-height .3s ease-in-out;
  min-height: 130px;
}

.animate-height-m {
  transition: min-height .3s ease-in-out;
  min-height: 150px;
}

.animate-height-m.active {
  transition: min-height .3s ease-in-out;
  min-height: 90px;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
  }
}

.bm-burger-button>button {
  display: none
}

.bm-overlay {
  background: white !important;
  border: none;
}

.bm-menu {
  padding: 24px;
  height: 100vh !important;
  overflow: hidden !important;
}